























































































































































































































































































































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import RandomChart from "@/components/RandomChart.vue";

@Component({
  name: 'LeaderboardResponsive',
  components: { RandomChart }
})
export default class LeaderboardResponsive extends Vue {
  protected sidebarOpen = false;
  protected notificationOpen = false;
  protected dropdownOpen = false;
}
